@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --dark-primary: #27243a;
  --dark-secondary: #4e4feb;
  --secondary-plus: #2c2ca1;
  --dark-tertiary: #242222;
  --light-primary: #eef0f2;
  --light-secondary: #4e4feb;
  --light-tertiary: #f4f4f6;
}

html {
  color-scheme: dark;
}

.main {
  @apply mt-[56px] p-2;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-style-btn {
  background: none;
  border: none;
}

@keyframes caretBlink {
  0%,
  100% {
    caret-color: transparent;
  }
  50% {
    caret-color: #4e4feb;
  }
}

.animate-caret {
  animation: caretBlink 1.2s step-end infinite;
}

.page-dark {
  min-height: 100vh;
  overflow-y: auto;
  background-color: var(--dark-primary);
}
.page-light {
  min-height: 100vh;
  overflow-y: auto;
  background-color: var(--light-primary);
}

/* Hide scrollbar for mobile and tablet screens */
@media screen and (max-width: 1000px) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Couleur de fond de la barre de défilement */
::-webkit-scrollbar-track {
  background-color: rgba(73, 80, 87, 0.6);
}

/* Couleur de remplissage de la poignée (barre de défilement) */
::-webkit-scrollbar-thumb {
  background-color: var(--secondary-plus);
  border-radius: 25px;
}

/* Couleur de survol de la poignée (barre de défilement) */
::-webkit-scrollbar-thumb:active {
  background-color: var(--secondary-plus);
}

/* Couleur de fond des boutons de flèche de défilement */
::-webkit-scrollbar-button {
  display: none;
}

/* Couleur de fond de la barre de défilement lors du défilement */
::-webkit-scrollbar-track-piece:start {
  background-color: rgba(244, 244, 246, 0.9);
}
::-webkit-scrollbar-track-piece:end {
  background-color: rgba(244, 244, 246, 0.9);
}
